<!--
 * @ Author: Clark
 * @ Create Time: 2023-02-05 20:20:57
 * @ Modified by: Your name
 * @ Modified time: 2023-02-14 11:50:50
 * @ Description: 营销管理>标签分组>用户分组>用户分组详情
 -->

<template>
  <div class="content">
    <ykc-form :label-position="'left'" label-width="120px" class="form-left">
      <ykc-detail-item label="场站名称">
        <span>{{ dealData(formData.stationName) }}</span>
      </ykc-detail-item>
      <ykc-detail-item label="故障发生时间">
        <span>{{ dealData(formData.failureTime) }}</span>
      </ykc-detail-item>
      <ykc-detail-item label="所在位置(车位)">
        <span>{{ dealData(formData.carLot) }}</span>
      </ykc-detail-item>

      <ykc-detail-item label="故障信息">
        <span>{{ dealData(formData.failureInfo) }}</span>
      </ykc-detail-item>
      <ykc-detail-item label="期望维修日期">
        <span>{{ dealData(formData.workDate) }}</span>
      </ykc-detail-item>
      <ykc-detail-item label="处理人员">
        <span>{{ dealData(formData.takeName) }}</span>
      </ykc-detail-item>
      <ykc-detail-item label="故障图片">
        <!-- <span>{{ dealData(formData.failurePicList) }}</span> -->
        <viewerDownload
          :images="this.formData.failurePicList"
          v-if="this.formData?.failurePicList?.length > 0">
          <img
            class="pic-img"
            v-for="(src, index) in this.formData.failurePicList"
            :src="src.url"
            :key="index" />
        </viewerDownload>
        <span v-else>——</span>
      </ykc-detail-item>
      <ykc-detail-item label="处理说明">
        <span>{{ dealData(formData.takeRemark) }}</span>
      </ykc-detail-item>

      <ykc-detail-item label="处理时间">
        <span>{{ dealData(formData.takeTime) }}</span>
      </ykc-detail-item>
      <ykc-detail-item label="维修图片">
        <!-- <span>{{ dealData(formData.takePicList) }}</span> -->
        <viewerDownload
          :images="this.formData.takePicList"
          v-if="this.formData?.takePicList?.length > 0">
          <img
            class="pic-img"
            v-for="(src, index) in this.formData.takePicList"
            :src="src.url"
            :name="src.name"
            :key="index" />
        </viewerDownload>
        <span v-else>——</span>
      </ykc-detail-item>
      <ykc-detail-item label="设备编码">
        <span>{{ dealData(formData.gunCode) }}</span>
      </ykc-detail-item>
    </ykc-form>
  </div>
</template>

<script>
  import { omManagementApi } from '@/service/apis';
  import { dealData } from '../../../../utils/index';
  import viewerDownload from '@/components/viewer-download.vue';

  export default {
    name: 'PropertymentDetail',
    components: { viewerDownload },
    props: {
      id: {
        type: Number,
      },
    },
    data() {
      return {
        formData: {},
        pageButtons: [
          {
            id: '0',
            text: '返回',
            type: 'plain',
            handleClick: () => {
              this.$router.go(-1);
            },
          },
        ],
      };
    },
    computed: {},
    created() {
      console.log('===', this.id);
      this.queryDetail();
    },
    methods: {
      dealData,
      queryDetail() {
        // 编辑回显
        if (this.id) {
          omManagementApi
            .operationDetail({ id: this.id })
            .then(res => {
              console.log(res);
              this.formData = res;
              console.log(this.formData);
            })
            .catch(() => {});
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .content {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 40px 20px;
    .pic-img {
      width: 60px;
      height: 60px;
      border-radius: 10px;
      cursor: pointer;
      margin-right: 20px;
    }
    .box-card {
      width: 100%;
      height: auto;
      margin: 10px 0px 0px 0px;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      .flexBox {
        display: flex;
        margin-bottom: 20px;
      }
      .status {
        margin-left: 10px;
        color: #fff;
        height: 20px;
        border-radius: 10px;
        min-width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 8px;
        line-height: 20px;
        &.stationStatus {
          &.status1000135 {
            background-color: #009f09;
          }
          &.status1000136 {
            background-color: #e81b1b;
          }
        }
        &.infoStatus {
          &.status1 {
            background-color: #009f09;
          }
          &.status2 {
            background-color: #ccc;
            color: #fff;
          }
          &.status0 {
            background-color: #e81b1b;
          }
        }
      }
      .title {
      }
    }
  }
</style>
