<!-------------------------------------------------
description:  运维中心 告警中心 告警信息列表
/**
 *@author ocean
 *@date 2024/2/28
*/
--------------------------------------------------->
<template>
  <div class="page-container">
    <custom-list
      ref="customList"
      table-title="运维信息列表"
      :show-header="true"
      :request-call="true"
      :search-data="searchData"
      :search-params="searchParams"
      :table-columns="tableColumns"
      :table-operate-buttons="tableOperateButtons"
      :header-buttons="headerButtons.filter(item => item.enabled())"
      :request-method="omManagementApi.operationInfoList"
      :operateFixedType="'right'"
      :operateButtons="tableOperateButtons.filter(item => item != '{null}')"
      @query-form="queryForm"
      @clear-form="clearForm" />
    <!-- 新增/编辑抽屉 -->
    <ykc-drawer
      :title="drawerTitle"
      :show.sync="showDrawer"
      :on-close="onAddDrawerClose"
      :before-cancel="onAddDrawerCancel">
      <template #footer>
        <div class="drawer-button-wrap">
          <template v-for="(btn, i) in drawerButtons">
            <ykc-button :type="btn.type" v-if="btn.enabled()" :key="i" @click="btn.handler">
              {{ btn.label }}
            </ykc-button>
          </template>
        </div>
      </template>
      <div class="drawer-main-body" v-if="showDrawer">
        <AddOrHandle
          v-if="drawerType === 'add' || drawerType === 'handle'"
          ref="addOrHandle"
          :id="id"
          :drawerType="drawerType"></AddOrHandle>
        <Detail v-if="drawerType === 'detail'" :id="id"></Detail>
      </div>
    </ykc-drawer>
  </div>
</template>

<script>
  import { omManagementApi, barrierPage, brandModelPage, treeUtils } from '@/service/apis';
  import { code, offlineExport } from '@/utils';
  import AddOrHandle from './AddOrHandle.vue';
  import Detail from './Detail.vue';
  import YkcDialog from '@/components/ykc-ui/dialog';
  import { loadDicItem } from '@/utils/dictionary';
  import CustomList from '@/components/biz/CustomList.vue';

  export default {
    name: 'OperationInfoList',
    components: { CustomList, AddOrHandle, Detail },
    data() {
      return {
        id: null,
        addressData: [], // 城市组件使用数据源
        showRuleConfigDialog: false,
        omManagementApi,
        showSMSNotificationRuleDialog: false,
        statusData: [
          { id: 1, name: '待处理' },
          { id: 2, name: '已处理' },
        ], // 状态数据
        stationIdData: [], // 归属电站组件使用数据源
        smsInfoRulesConfigs: [], //  短信通知规则配置
        drawerType: 'add',
        showDrawer: false,
        searchParams: {
          date: [],
        },
        drawerButtons: [
          {
            label: '取消',
            type: 'plain',
            enabled: () => {
              return this.drawerType !== 'detail';
            },
            handler: () => {
              YkcDialog({
                dialogType: 'feedback',
                showTitle: false,
                showFooter: true,
                desc: '取消后操作将不被保存，确认是否取消？',
                onCancel: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                },
                onConfirm: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                  this.showDrawer = false; // 隐藏抽屉
                },
              });
            },
          },
          {
            label: '确认',
            enabled: () => {
              return this.drawerType !== 'detail';
            },
            handler: () => {
              console.log(this.$refs.addOrHandle);
              this.$refs.addOrHandle.submitForm().then(() => {
                console.log('123123');
                this.$refs.customList.searchTableList();
                // this.omManagementApi.operationInfoList(this.searchParams);
                this.showDrawer = false;
              });
            },
          },
          {
            label: '关闭',
            enabled: () => {
              return this.drawerType === 'detail';
            },
            handler: () => {
              this.showDrawer = false;
            },
          },
        ],
        tableColumns: [
          {
            label: '工单编号',
            prop: 'id',
            width: 100,
            scopedSlots: { defaultTitle: () => '' },
          },
          {
            label: '站点名称',
            prop: 'stationName',
            width: 150,
            scopedSlots: { defaultTitle: () => '' },
          },
          {
            label: '省市区',
            prop: 'pcd',
            width: 200,
            scopedSlots: { defaultTitle: () => '' },
          },

          {
            label: '设备编码',
            prop: 'gunCode',
            width: 200,
          },
          {
            label: '故障信息',
            minWidth: 150,
            prop: 'failureInfo',
            // scopedSlots: {
            //   default: ({ row }) => {
            //     return (
            //       <el-tooltip effect="dark" content={row.failureInfo} placement="top-start">
            //         <span>{row.failureInfo}</span>
            //       </el-tooltip>
            //     );
            //   },
            // },
          },
          {
            label: '期望维修日期',
            prop: 'workDate',
            width: 180,
          },
          { label: '状态', width: 100, prop: 'statusName' },
          {
            label: '故障发生时间',
            prop: 'failureTime',
            width: 200,
          },
          { label: '更新人', width: 150, prop: 'modifiedByName' },
          {
            label: '更新时间',
            minWidth: 200,
            prop: 'modifiedTime',
          },
        ],
        tableOperateButtons: [
          {
            render: (h, data, { row }) =>
              row.status === 1 ? (
                <ykc-button
                  type="text"
                  onClick={() => {
                    console.log(h, data);
                    this.id = row.id;
                    this.showDrawer = true;
                    this.drawerType = 'handle';
                  }}>
                  处理
                </ykc-button>
              ) : (
                <ykc-button
                  type="text"
                  onClick={() => {
                    this.id = row.id;
                    this.drawerType = 'detail';
                    this.showDrawer = true;
                  }}>
                  详情
                </ykc-button>
              ),
          },
        ],
      };
    },
    computed: {
      drawerTitle() {
        switch (this.drawerType) {
          case 'add':
            return `新增工单`;
          case 'detail':
            return `工单详情`;
          default:
            return `工单处理`;
        }
      },

      headerButtons() {
        return [
          {
            enabled: () => code('maintain:work:add'),
            // enabled: () => true,
            label: '新建工单',
            handle: () => {
              this.drawerType = 'add';
              this.showDrawer = true;
            },
          },
          {
            enabled: () => code('maintain:work:export'),
            // enabled: () => true,
            label: '导出',
            handle: (data, ctx) => {
              offlineExport(
                {
                  downloadType: 'statistics_work_info_export',
                  jsonNode: {
                    ...ctx.searchParams,
                  },
                },
                ctx.pageInfo.total
              );
            },
          },
        ];
      },
      searchData() {
        return [
          {
            comName: 'YkcCascader',
            key: 'districtIds',
            label: '城市选择',
            configSet: {
              label: 'name',
              value: 'id',
              children: 'subNodes',
            },
            options: this.addressData,
          },

          {
            multiple: false,
            label: '站点',
            key: 'stationId',
            comName: 'YkcDropdown',
            placeholder: '请选择站点',
            data: this.stationIdData,
            remote: true,
          },
          {
            key: 'date',
            label: '故障发生时间',
            comName: 'YkcDatePicker',
            placeholder: '请选择故障发生时间',
          },
          {
            multiple: false,
            label: '状态',
            key: 'status',
            comName: 'YkcDropdown',
            placeholder: '请选择状态',
            data: this.statusData,
            remote: true,
          },
        ];
      },
    },
    created() {
      // this.dictionaryAlarmType = loadDicItem('alarm_type') || []; // 告警分类
      // this.dictionaryAlarmLevel = loadDicItem('alarm_level') || []; // 告警等级

      // 近30天初始化时间
      // const startDate = getLastTime('yyyy-MM-dd', 30);
      // const endDate = getLastTime('yyyy-MM-dd', 0);

      // this.searchParams.date = [startDate, endDate];
      // this.searchParams.startData = startDate;
      // this.searchParams.endDate = endDate;

      // this.dictionaryAlarmReason = this.mergeArrays(
      //   loadDicItem('alarm_reason_1'),
      //   loadDicItem('alarm_reason_2'),
      //   loadDicItem('alarm_reason_3'),
      //   loadDicItem('alarm_reason_4')
      // ); // 告警事项
      this.requestGetDistrict();
      this.requestStationIdDataList();
      this.requestBrandAndModelList();
    },
    methods: {
      /**
       * 获取区域数据
       */
      requestGetDistrict() {
        treeUtils
          .getDistrict({})
          .then(res => {
            this.addressData = res.districts;
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 获取电站数据
       */
      requestStationIdDataList() {
        barrierPage
          .findStationList({})
          .then(res => {
            console.log('获取电站数据+++++', res);
            res.map(item => {
              return this.stationIdData.push({
                id: item.stationId,
                name: item.stationName,
              });
            });
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 获取电桩型号列表
       */
      requestBrandAndModelList() {
        brandModelPage
          .brandModelList({ keyword: '' })
          .then(res => {
            console.log('获取电桩型号列表+++++', res);
            res.map(item => {
              return this.brandAndModelData.push({
                id: item.id,
                name: item.modelName,
              });
            });
          })
          .catch(err => {
            console.log(err);
          });
      },
      queryForm(searchData) {
        // 时间选择
        if (searchData.date && Array.isArray(searchData.date) && searchData.date.length > 0) {
          [this.searchParams.startDate, this.searchParams.endDate] = searchData.date;
        }
        if (searchData.districtIds) {
          [, , searchData.districtId] = searchData.districtIds;
        }
      },
      /**
       * 合并多个数组
       */
      mergeArrays(...arrays) {
        // 使用reduce方法将所有数组合并为一个数组
        return arrays.reduce((mergedArray, currentArray) => {
          return mergedArray.concat(currentArray);
        }, []);
      },
      clearForm() {
        // 近30天初始化时间
        // const startDate = getLastTime('yyyy-MM-dd', 30);
        // const endDate = getLastTime('yyyy-MM-dd', 0);
        // this.searchParams.date = [startDate, endDate];
        // this.searchParams.start = startDate;
        // this.searchParams.endDate = endDate;
      },
      /**
       * @desc 点击抽屉取消按钮 callback
       * */
      onAddDrawerCancel(drawerDone) {
        YkcDialog({
          dialogType: 'feedback',
          showTitle: false,
          showFooter: true,
          desc: '取消后操作将不被保存，确认是否取消？',
          onCancel: done => {
            done();
          },
          onConfirm: done => {
            done();
            drawerDone();
          },
        });
      },
      onAddDrawerClose(done) {
        done();
      },
    },
  };
</script>

<style scoped>
  .lable-red {
    color: #f56c6c;
  }
  .lable-orange {
    color: #e6a23c;
  }
  .lable-green {
    color: #67c23a;
  }
</style>
