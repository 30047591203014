<!-- 系统设置 权限管理 账号管理 新增或编辑或数据权限 -->
<template>
  <ykc-form class="el-form-wrap">
    <!-- 新建工单 -->
    <ykc-form ref="addForm" :model="addForm" :rules="addRules" v-if="drawerType === 'add'">
      <ykc-form-item label="场站名称" prop="stationId">
        <ykc-dropdown
          :multiple="false"
          :clearable="true"
          :data="stationIdData"
          v-model="addForm.stationId"
          @change="selectChange"></ykc-dropdown>
      </ykc-form-item>
      <ykc-form-item label="设备编码" prop="gunCode">
        <ykc-dropdown
          :multiple="false"
          :clearable="true"
          :isMultiLine="true"
          :disabled="!addForm.stationId"
          :data="gunData"
          v-model="addForm.gunCode"></ykc-dropdown>
      </ykc-form-item>

      <ykc-form-item label="故障发生时间" prop="failureTime">
        <ykc-date-picker
          type="datetime"
          v-model="addForm.failureTime"
          maxlength="30"
          placeholder="请选择故障发生时间"></ykc-date-picker>
      </ykc-form-item>

      <ykc-form-item label="所在位置(车位)" prop="carLot">
        <ykc-input
          v-model="addForm.carLot"
          maxlength="30"
          placeholder="请输入所在位置(车位)"></ykc-input>
      </ykc-form-item>
      <ykc-form-item label="期望维修日期" prop="workDate">
        <ykc-date-picker
          type="date"
          v-model="addForm.workDate"
          maxlength="30"
          placeholder="请选择期望维修日期"></ykc-date-picker>
      </ykc-form-item>
      <ykc-form-item label="故障信息" prop="failureInfo">
        <ykc-input
          type="textarea"
          maxlength="500"
          v-model="addForm.failureInfo"
          placeholder="请输入故障信息"></ykc-input>
      </ykc-form-item>

      <ykc-form-item
        prop="failurePicList"
        label="故障图片"
        class="file-error img-error"
        bottomTip="温馨提示：请上传大小1M，尺寸750pxx1334px的jpg、jepg、png图片">
        <div class="flex upload-img-box">
          <ykc-upload
            class="imgUpload"
            ref="adsImg"
            :key="i"
            v-for="i in 3"
            @handleRemove="handleRemove(i)"
            @handleChange="
              (file, fileList, isCheck) => uploadAd(file, fileList, isCheck, i)
            "></ykc-upload>
        </div>
      </ykc-form-item>
    </ykc-form>
    <!-- 处理工单 -->
    <ykc-form
      ref="handleForm"
      :model="handleForm"
      :rules="handleRules"
      v-if="drawerType === 'handle'">
      <ykc-form-item label="处理说明" prop="takeRemark">
        <ykc-input
          type="textarea"
          maxlength="500"
          v-model="handleForm.takeRemark"
          placeholder="请输入备注"></ykc-input>
      </ykc-form-item>
      <ykc-form-item label="处理人员" prop="takeId">
        <ykc-dropdown
          :multiple="false"
          :clearable="true"
          :data="takeData"
          v-model="handleForm.takeId"></ykc-dropdown>
      </ykc-form-item>
      <ykc-form-item label="处理时间" prop="takeTime">
        <ykc-date-picker
          type="datetime"
          v-model="handleForm.takeTime"
          maxlength="30"
          placeholder="请选择处理时间"></ykc-date-picker>
      </ykc-form-item>
      <ykc-form-item
        prop="takePicList"
        label="维修图片"
        class="file-error img-error"
        bottomTip="温馨提示：请上传大小1M，尺寸750pxx1334px的jpg、jepg、png图片">
        <div class="flex upload-img-box">
          <ykc-upload
            class="imgUpload"
            ref="adsImg"
            :key="i"
            v-for="i in 3"
            @handleRemove="handleRemove(i)"
            @handleChange="
              (file, fileList, isCheck) => uploadAd(file, fileList, isCheck, i)
            "></ykc-upload>
        </div>
      </ykc-form-item>
    </ykc-form>
  </ykc-form>
</template>

<script>
  import {
    barrierPage,
    uploadUtils,
    marketManage,
    omManagementApi,
    orderlyPage,
  } from '@/service/apis';

  export default {
    props: {
      id: {
        type: Number,
        default: null,
      },
      drawerType: {
        type: String,
      },
    },
    name: 'AddOrEdit',
    data() {
      return {
        detail: {},
        stationIdData: [],
        gunData: [], // 设备编码
        takeData: [], // 处理人员列表
        addForm: {
          stationId: '',
          gunCode: null,
          addressId: '',
          carLot: '',
          failurePicList: [],
          workDate: '',
        },
        addRules: {
          stationId: [{ required: true, message: '请选择站点', trigger: 'blur' }],
          gunCode: [{ required: true, message: '请选择设备编码', trigger: 'blur' }],
          failureTime: [{ required: true, message: '请选择故障发生时间', trigger: 'blur' }],
          workDate: [{ required: true, message: '请选择期望维修日期', trigger: 'blur' }],
          failureInfo: [{ required: true, message: '请输入故障信息', trigger: 'blur' }],
          carLot: [{ required: true, message: '请输入所在车位', trigger: 'blur' }],
          // failurePicList: [{ required: false, message: '请上传故障图片', trigger: 'blur' }],
        },
        handleForm: {
          takeRemark: '',
          takeId: '',
          takeTime: '',
          takePicList: [],
        },
        handleRules: {
          takeId: [{ required: true, message: '请选择处理人员', trigger: 'blur' }],
          takeRemark: [{ required: true, message: '请输入处理说明', trigger: 'blur' }],
          takeTime: [{ required: true, message: '请选择处理时间', trigger: 'blur' }],
          // takePicList: [{ required: false, message: '请上传维修图片', trigger: 'blur' }],
        },
      };
    },
    created() {
      if (this.drawerType === 'add') {
        this.doFetchOrgInfos(); // 获取归属电站列表
      } else {
        this.getTakeList();
      }
    },
    computed: {
      formData() {
        const formData = {
          stationId: this.addForm.stationId,
          gunCode: this.addForm.gunCode,
          failureInfo: this.addForm.failureInfo,
          failureTime: this.addForm.failureTime,
          failurePicList: this.addForm.failurePicList,
          workDate: this.addForm.workDate,
          carLot: this.addForm.carLot,
        };
        return formData;
      },
      handleFormData() {
        const handleFormData = {
          // id: this.id,
          takeRemark: this.handleForm.takeRemark,
          takeId: this.handleForm.takeId,
          takeTime: this.handleForm.takeTime,
          takePicList: this.handleForm.takePicList,
        };
        if (this.id) {
          handleFormData.id = this.id;
        }
        return handleFormData;
      },
    },
    methods: {
      /**
       * @desc 获取归属列表
       * */
      doFetchOrgInfos() {
        barrierPage
          .findStationList({})
          .then(res => {
            res.map(item => {
              return this.stationIdData.push({
                id: String(item.stationId),
                name: item.stationName,
              });
            });
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * @desc 获取设备编码
       * */
      doFindStationAndPile() {
        marketManage
          .findStationPile({
            stationId: this.addForm?.stationId,
          })
          .then(res => {
            res.forEach(item => {
              this.gunData.push({
                id: item.pileCode,
                name: item.pileCode,
              });
            });
          });
      },
      /**
       * @desc 获取人员列表
       * */
      getTakeList() {
        omManagementApi.operationsUserList({}).then(res => {
          res.forEach(item => {
            this.takeData.push({
              id: item.id,
              name: item.accountName,
            });
          });
        });
      },

      /**
       * 获取电站
       */
      selectChange(e) {
        this.addForm.stationId = e;
        this.addForm.gunCode = null;
        this.gunData = [];
        this.doFindStationAndGun(); // 获取设备编码列表
      },
      /**
       * @desc 查询电站和终端信息
       * */
      doFindStationAndGun() {
        orderlyPage
          .findStationAndGun({ stationIdList: [this.addForm?.stationId] })
          .then(res => {
            res[0].gunVOList?.map(item => {
              return this.gunData.push({
                id: item.gunCode,
                name: item.gunCode,
              });
            });
          })
          .catch(err => {
            console.log(err);
          });
      },
      handleRemove(idx) {
        if (this.drawerType === 'add') {
          this.addForm.failurePicList[idx - 1] = null;
        } else {
          this.handleForm.takePicList[idx - 1] = null;
        }
        console.log(this.addForm, this.handleForm);
      },
      uploadAd(file, fileList, isCheck, index) {
        if (isCheck) {
          const formData = new FormData();
          const param = {
            file: file.raw,
            bizName: '',
          };
          // 处理入参格式
          Object.entries(param).forEach(([k, v]) => {
            formData.append(k, v);
          });
          uploadUtils.uploadImg(formData).then(res => {
            if (this.drawerType === 'add') {
              this.addForm.failurePicList[index - 1] = {
                url: res,
                name: file.name,
              };
            } else {
              this.handleForm.takePicList[index - 1] = {
                url: res,
                name: file.name,
              };
            }
          });
        }
      },
      /**
       * 表单提交事件，用于父组件定义的"完成"或"保存"按钮点击事件
       */
      submitForm() {
        return new Promise(resolve => {
          this.validateForm().then(() => {
            if (this.drawerType === 'add') {
              omManagementApi
                .operationAdd(this.formData)
                .then(res => {
                  console.log(res);
                  this.$message({
                    message: '保存成功',
                    type: 'success',
                  });
                  resolve();
                })
                .catch(() => {});
            } else {
              omManagementApi
                .operationHandle(this.handleFormData)
                .then(res => {
                  console.log(res);
                  this.$message({
                    message: '保存成功',
                    type: 'success',
                  });
                  resolve();
                })
                .catch(() => {});
            }
          });
        });
      },
      /**
       *
       */
      validateForm() {
        // console.log(this.drawerType + 'Form', this.$refs[this.drawerType + 'form'], this.$refs);
        return new Promise((resolve, reject) => {
          this.$refs[`${this.drawerType}Form`].validate(valid => {
            if (valid) {
              resolve();
            } else {
              reject();
            }
          });
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .drawer-button-wrap {
    padding: 0 10px;
    button {
      margin-right: 5px;
    }
  }
  .imgUpload {
    margin-right: 40px;
  }
</style>
